import {
  getProductFiltersByFilteredSearch,
  getProductByFilteredSearch,
  getProductFiltersBySearch,
  getProductByPartfinder,
  getProductFiltersByPartfinder,
  getProductFilteredSortedByPartfinder,
} from "@storefront/core/data-resolver/products";

import store from "@/store";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import { isServer } from "@storefront/core/helpers";
const state = () => ({
  current: null,
  currentSearch: "",
  menuCategories: [],
  productFilters: [],
  activeFilter: [],
  priceFilter: [0, 0],
  priceFilterMin: 0,
  priceFilterMax: 0,
  filteredCount: 0,
  products: [],
  ShowProducts: [],
  currentPage: 1,
  prodsPerPage: null,
  totalPages: 1,
  totalProducts: 0,
  sorting: "default",
  currentSorting: null,
  loadProducts: false,
  isBusy: false,
});

const actions = {
  /**
   * Load products by search string
   *
   *
   * @param {object} string search, integer totalPages
   */
  async loadProductsBySearch({ commit, getters }) {
    commit("setLoadProducts", true);
    const searchVal = getters["getCurrentSearch"];

    const currPage = getters["getCurrentPage"];
    const perPage = getters["getProductsPerPage"];
    const totalPages = getters["getTotalPages"];



    if (currPage > totalPages) {
      commit("setLoadProducts", false);
      return;
    }

    const sort = getters["getCurrentSorting"];

    const filters = [];

    const isPriceFilterActive = getters["getIsPriceFilterActive"];

    if (isPriceFilterActive) {
      const priceFilter = getters["getPriceFilter"];
      filters.push({ attribute_code: "price", values: priceFilter });
    }

    const activeFilters = getters["getActiveFilter"];
    activeFilters.forEach((el) => {
      const index = filters.findIndex(
        (o) => o.attribute_code == el.attribute_code
      );

      if (index != -1) {
        filters[index].values.push(el.value);
      } else {
        filters.push({ attribute_code: el.attribute_code, values: [el.value] });
      }
    });

    const retv = await getProductByFilteredSearch(
      searchVal,
      currPage,
      perPage,
      { sort, filters }
    );

    if (retv.items) {
      commit("setProducts", retv.items);
    } else {
      commit("setProducts", []);
    }
    commit("setLoadProducts", false);
  },

  /**
   * load product filters based on search
   *
   * @param {object} string search
   */
  async loadSearchFilters({ commit, getters }) {
    commit("setLoadProducts", true);
    const searchVal = getters["getCurrentSearch"];

    const sort = getters["getCurrentSorting"];

    const filters = [];

    const isPriceFilterActive = getters["getIsPriceFilterActive"];

    if (isPriceFilterActive) {
      const priceFilter = getters["getPriceFilter"];
      filters.push({ attribute_code: "price", values: priceFilter });
    }

    const activeFilters = getters["getActiveFilter"];

    activeFilters.forEach((el) => {
      let matched = false; // Flag to track if a match is found

      for (const o of filters) {
        if (o.attribute_code === el.attribute_code) {
          matched = true;
          if (el.value !== undefined) {
            o.values.push(el.value);
          }
          break; // Exit the loop once a match is found
        }
      }

      if (!matched) {
        if (Array.isArray(el.value)) {
          filters.push({ attribute_code: el.attribute_code, values: el.value });
        } else {
          filters.push({ attribute_code: el.attribute_code, values: [el.value] });
        }
      }
    });

    const currPage = getters["getCurrentPage"];
    const perPage = getters["getProductsPerPage"];

    const retval = await getProductFiltersByFilteredSearch(
      searchVal,
      currPage,
      perPage,
      { sort, filters }
    ).catch((e) => {
      Logger.error(
        "getProductFiltersByFilteredSearch",
        "category store actions load",
        e
      )();
      throw e;
    });

    const products = retval.items;
    const totalPages = retval.page_info.total_pages;
    const totalProducts = retval.total_count;

    commit("setTotalPages", totalPages);
    commit("setTotalProducts", totalProducts);
    commit("setProducts", products);

    commit("setLoadProducts", false);

    const uFilters = [];

    if (retval.aggregations && retval.aggregations.length) {
      retval.aggregations.forEach((element) => {
        if (element.attribute_code != "category_id" && element.attribute_code != "category_uid") {
          if (element.attribute_code == "price") {
            if (parseInt(element.min_value) < 0) {
              element.min_value = 0;
            }
            element.min = parseInt(element.min_value);
            if (Math.ceil(element.max_value) < element.min_value) {
              element.max = element.min_value;
            } else {
              element.max = Math.ceil(element.max_value);
            }
            if (element.min < 0) {
              element.min = 0;
            }
            if (element.max < 0) {
              element.max = 0;
            }

            const priceFilter = getters["getPriceFilter"];

            if (priceFilter[0] === 0 && priceFilter[1] === 0) {
              commit("setPriceFilterMin", element.min);
              commit("setPriceFilterMax", element.max);
              commit("addPriceFilter", [element.min, element.max]);
            }

            if (priceFilter[0] < element.min || priceFilter[1] > element.max) {
              commit("setPriceFilter", [element.min, element.max]);
            }
          }
          uFilters.push(element);
        }
      });
    }
    commit("setProductFilters", uFilters);
  },

  /**
   * load product filters based on search and filters
   *
   * @param {object} string search
   */
  async applySearchFilters({ commit, dispatch, getters }, filter) {
    const searchVal = getters["getCurrentSearch"];
    const { sort, page, filters } = filter;

    let { pageSize } = filter;
    if (isNaN(parseInt(pageSize))) pageSize = 12;

    const retval = await getProductFiltersBySearch(searchVal, pageSize);

    const totalPages = retval.page_info.total_pages;
    const totalProducts = retval.total_count;

    commit("setTotalPages", totalPages);
    commit("setTotalProducts", totalProducts);

    const priceFilter = [0, 0];
    const productFilters = [];

    if (retval.aggregations && retval.aggregations.length) {
      retval.aggregations.forEach((element) => {
        if (element.attribute_code != "category_id" && element.attribute_code != "category_uid") {
          if (element.attribute_code == "price") {
            if (parseInt(element.min_value) < 0) {
              element.min_value = 0;
            }
            element.min = parseInt(element.min_value);
            if (Math.ceil(element.max_value) < element.min_value) {
              element.max = element.min_value;
            } else {
              element.max = Math.ceil(element.max_value);
            }
            if (element.min < 0) {
              element.min = 0;
            }
            if (element.max < 0) {
              element.max = 0;
            }

            priceFilter[0] = element.min;
            priceFilter[1] = element.max;

            commit("setPriceFilterMin", element.min);
            commit("setPriceFilterMax", element.max);
            commit("addPriceFilter", priceFilter);
          }
          productFilters.push(element);
        }
      });
    }
    commit("setProductFilters", productFilters);

    if (!isNaN(parseInt(page)) && parseInt(page) <= totalPages) {
      commit("addCurrentPage", page);
    }

    if (!isNaN(parseInt(pageSize))) {
      commit("addProductsPerPage", pageSize);
    } else {
      commit("addProductsPerPage", 12);
    }

    if (sort) commit("addSorting", sort);

    const activeFilters = [];
    let activePriceFilter = [];

    if (filters) {
      Object.keys(filters).forEach((element) => {
        const attrFilters = productFilters.find(
          (el) => el.attribute_code == element
        );

        if (attrFilters != null) {
          if (element == "price") {
            const [priceMin, priceMax] = priceFilter;

            const [min, max] = filters["price"].split("-");

            if (!isNaN(parseInt(min)) && !isNaN(parseInt(max))) {
              activePriceFilter = [
                priceMin > parseInt(min) ? priceMin : parseInt(min),
                priceMax < parseInt(max) ? priceMax : parseInt(max),
              ];
            }
          } else {
            filters[element].split(",").forEach((value) => {
              const activeFilter = attrFilters.options.find(
                (option) => option.value == value
              );
              if (activeFilter) {
                const filterBy = {
                  attribute_code: element,
                  type_label: attrFilters.label,
                  value: value,
                  label: activeFilter.label,
                };
                activeFilters.push(filterBy);
              }
            });
          }
        }
      });
    }

    if (activeFilters.length) commit("addActiveFilter", activeFilters);
    if (activePriceFilter.length) commit("addPriceFilter", activePriceFilter);

    if (activeFilters.length || activePriceFilter.length) {
      const filters = [];
      const isPriceFilterActive = getters["getIsPriceFilterActive"];
      if (isPriceFilterActive) {
        const priceFilter = getters["getPriceFilter"];
        filters.push({ attribute_code: "price", values: priceFilter });
      }
      const activeFilters = getters["getActiveFilter"];
      activeFilters.forEach((el) => {
        const index = filters.findIndex(
          (o) => o.attribute_code == el.attribute_code
        );

        if (index != -1) {
          filters[index].values.push(el.value);
        } else {
          filters.push({
            attribute_code: el.attribute_code,
            values: [el.value],
          });
        }
      });

      const retval = await getProductFiltersBySearch(
        searchVal,
        pageSize,
        filters
      ).catch((e) => {
        Logger.error(
          "getProductFiltersBySearch",
          "category store actions load",
          e
        )();
        throw e;
      });

      const currentPage = getters["getCurrentPage"];
      const totalPages = retval.page_info.total_pages;
      const totalProducts = retval.total_count;

      commit("setTotalPages", totalPages);
      commit("setTotalProducts", totalProducts);

      if (currentPage > totalPages) commit("addCurrentPage", 1);

      const uFilters = [];

      if (retval.aggregations && retval.aggregations.length) {
        retval.aggregations.forEach((element) => {
          if (element.attribute_code != "category_id" && element.attribute_code != "category_uid") {
            if (element.attribute_code == "price") {
              if (parseInt(element.min_value) < 0) {
                element.min_value = 0;
              }
              element.min = parseInt(element.min_value);
              if (Math.ceil(element.max_value) < element.min_value) {
                element.max = element.min_value;
              } else {
                element.max = Math.ceil(element.max_value);
              }
              if (element.min < 0) {
                element.min = 0;
              }
              if (element.max < 0) {
                element.max = 0;
              }

              const priceFilter = getters["getPriceFilter"];

              if (priceFilter[0] === 0 && priceFilter[1] === 0) {
                commit("setPriceFilterMin", element.min);
                commit("setPriceFilterMax", element.max);
                commit("addPriceFilter", [element.min, element.max]);
              }
            }
            uFilters.push(element);
          }
        });
      }
      commit("setProductFilters", uFilters);
    }
    await dispatch("loadProductsBySearch");
  },

  /**
   * load the search
   *
   * @param {object} string search
   * @returns search object
   */
  async load({ commit, dispatch }, { search = null, filter = false }) {
    commit("setCurrentSearch", search);

    // commit("setIsBusy", true);
    if (search == null) {
      commit("setProductFilters", {});
      commit("resetActiveFilter");
      commit("resetPriceFilter");
      commit("resetPageInfo");
      commit("resetSorting");

      return false;
    } else {
      commit("setProducts", []);
      commit("setProductFilters", {});
      commit("resetActiveFilter");
      commit("resetPriceFilter");
      commit("resetPageInfo");
      commit("resetSorting");
      commit("setConfigSettings");
      if (filter !== false) {
        await dispatch("applySearchFilters", filter);
      } else {
        await dispatch("loadSearchFilters");
      }
    }

    return true;
  },

  async loadPartfinder({ commit, dispatch }) {
    commit("setProducts", []);
    dispatch("loadFiltersPathfinder");

    const retval = await getProductByPartfinder(1);
    commit("addProducts", retval.items);
    const totalPages = retval.page_info.total_pages;
    if (!isServer) {
      dispatch("loadProductsPathfinder", {
        totalPages: totalPages,
      });
    }

    return true;
  },

  async loadFilteredSortedPartfinder({ getters, commit, dispatch }) {
    commit("setProducts", []);
    dispatch("loadFiltersPathfinder");
    
    //Get Filters
    const filters = [];
    const isPriceFilterActive = getters["getIsPriceFilterActive"];

    if (isPriceFilterActive) {
      const priceFilter = getters["getPriceFilter"];
      filters.push({ attribute_code: "price", values: priceFilter });
    }

    const activeFilters = getters["getActiveFilter"];
    activeFilters.forEach((el) => {
      let matched = false; // Flag to track if a match is found

      for (const o of filters) {
        if (o.attribute_code === el.attribute_code) {
          matched = true;
          if (el.value !== undefined) {
            o.values.push(el.value);
          }
          break; // Exit the loop once a match is found
        }
      }

      if (!matched) {
        if (Array.isArray(el.value)) {
          filters.push({ attribute_code: el.attribute_code, values: el.value });
        } else {
          filters.push({ attribute_code: el.attribute_code, values: [el.value] });
        }
      }
    });
    
    const currPage = getters["getCurrentPage"] || 1;
    const perPage = getters["getProductsPerPage"] || 12;
    const sort = getters["getCurrentSorting"];

    const retval = await getProductFilteredSortedByPartfinder(
      currPage,
      perPage,
      { sort, filters }
    );

    //Fetch Results
    const products = retval.items;
    const totalPages = retval.page_info.total_pages;
    const totalProducts = retval.total_count;

    //commit the result
    commit("setProducts", products);
    commit("setTotalPages", totalPages);
    commit("setTotalProducts", totalProducts);
    
    if (!isServer) {
      dispatch("loadProductsPathfinder", {
        totalPages: totalPages,
      });
    }

    return true;
  },

  async loadProductsPathfinder() {
    // console.log("totalPages", totalPages);
    // await getProductByPartfinder(1);
    // for (let page = 2; page < totalPages + 1; page++) {
      // const retv = await getProductByPartfinder(page);
      // commit("addProducts", retv.items);
    // }
  },

  async loadFiltersPathfinder({ getters, commit }) {

    //Get Filters
    const getFilters = [];
    const isPriceFilterActive = getters["getIsPriceFilterActive"];

    if (isPriceFilterActive) {
      const priceFilter = getters["getPriceFilter"];
      getFilters.push({ attribute_code: "price", values: priceFilter });
    }
    const activeFilters = getters["getActiveFilter"];
    if(activeFilters) {
      activeFilters.forEach((el) => {
        let matched = false;

        for (const o of getFilters) {
          if (o.attribute_code === el.attribute_code) {
            matched = true;
            if (el.value !== undefined) {
              o.values.push(el.value);
            }
            break;
          }
        }

        if (!matched) {
          if (Array.isArray(el.value)) {
            getFilters.push({ attribute_code: el.attribute_code, values: el.value });
          } else {
            getFilters.push({ attribute_code: el.attribute_code, values: [el.value] });
          }
        }
      });
    }
    
    // Call API
    const filters = await getProductFiltersByPartfinder(getFilters).catch((e) => {
      Logger.error(
        "getProductFiltersBySearch",
        "category store actions load",
        e
      )();
      throw e;
    });

    const uFilters = [];
    if (filters.aggregations && filters.aggregations.length) {
      filters.aggregations.forEach((element) => {
        if (element.attribute_code != "category_id" && element.attribute_code != "category_uid") {

          if (element.attribute_code == "price") {
            element.min = parseInt(element.min_value);
            element.max = Math.ceil(element.max_value);
            const priceFilter = getters["getPriceFilter"];

            if (priceFilter[0] === 0 && priceFilter[1] === 0) {
              commit("setPriceFilterMin", element.min);
              commit("setPriceFilterMax", element.max);
              commit("addPriceFilter", [element.min, element.max]);
            }

            if (priceFilter[0] < element.min || priceFilter[1] > element.max) {
              commit("setPartFinderPriceFilter", [element.min, element.max]);
            }

            uFilters.push(element);
          } 
          else {
            if(element.options) {
              element.value = element.options.map(option => option.value);
              uFilters.push(element);
            }
          }
        }
      });
    }
    commit("setProductFilters", uFilters);
  },

  async loadPartfinderFilters({ commit }) {
    const filters = await getProductFiltersByPartfinder().catch((e) => {
      Logger.error(
        "getProductFiltersByPartfinder",
        "category store actions load",
        e
      )();
      throw e;
    });
    const uFilters = [];

    if (filters.aggregations && filters.aggregations.length) {
      filters.aggregations.forEach((element) => {
        if (element.attribute_code != "category_id" && element.attribute_code != "category_uid") {
          if (element.attribute_code == "price") {
            element.min = parseInt(element.min_value);

            element.max = Math.ceil(element.max_value);

            commit("setPriceFilter", [element.min, element.max]);
          }
          uFilters.push(element);
        }
      });
    }
    commit("setProductFilters", uFilters);
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  setCurrentCategory(state, data) {
    state.current = data;
    const breadcrumbs = data.breadcrumbs;
    let currentName = "undefined";
    if (typeof data.name == "string") {
      currentName = data.name;
    }
    const bcrumb = { current: currentName, routes: [] };
    if (breadcrumbs != null) {
      breadcrumbs.sort((a, b) => {
        if (a.category_level < b.category_level) {
          return -1;
        }
        if (a.category_level > b.category_level) {
          return 1;
        }
        return 0;
      });
      let path = "";
      breadcrumbs.forEach((element) => {
        if (path.length > 0) {
          path = path + "/";
        }
        path = path + element.category_url_key;
        let name = "undefined";
        if (typeof element.category_name == "string") {
          name = element.category_name;
        }
        const bc = {
          name: name,
          route_link: path,
        };
        bcrumb.routes.push(bc);
      });
    }
    //const bcrumb = { current: data.name, routes: routes };
    store.commit("breadcrumbs/set", bcrumb);
  },
  setConfigSettings(state) {
    if (state.prodsPerPage === null) {
      if (config.products?.perPage) {
        state.prodsPerPage = config.products.perPage;
      } else {
        state.prodsPerPage = 12;
      }
    }
  },
  setCurrentSearch(state, data) {
    state.currentSearch = data;
  },
  setProductFilters(state, data) {
    state.productFilters = data;
  },
  addActiveFilter(state, data) {
    state.activeFilter = data;
  },
  setLoadProducts(state, data) {
    state.loadProducts = data;
  },
  resetActiveFilter(state) {
    state.activeFilter = [];
  },
  setProducts(state, data) {
    state.products = data;
    state.ShowProducts = data;
  },
  addCurrentPage(state, data) {
    if (data >= 1) {
      state.currentPage = data;
    }
  },
  setCurrentPage(state, data) {
    if (data >= 1 && data <= state.totalPages) {
      state.currentPage = data;
      store.dispatch("search/loadProductsBySearch");
    }
  },
  setPartfinderCurrentPage(state, data) {
    if (data >= 1 && data <= state.totalPages) {
      state.currentPage = data;
      store.dispatch("search/loadFilteredSortedPartfinder");
    }
  },
  resetCurrentPage(state) {
    state.currentPage = 1;
  },
  addProductsPerPage(state, data) {
    state.prodsPerPage = data;
  },
  setProductsPerPage(state, data) {
    state.prodsPerPage = data;
    store.commit("search/resetCurrentPage");

    const totalProducts = state.totalProducts;
    const totalPages = Math.ceil(state.totalProducts / data);

    state.totalPages = totalPages;

    if (totalProducts > 0) {
      store.dispatch("search/loadProductsBySearch");
    }
  },
  setTotalPages(state, data) {
    state.totalPages = data;
  },
  setTotalProducts(state, data) {
    state.totalProducts = data;
  },
  resetPageInfo(state) {
    state.currentPage = 1;
    state.totalPages = 1;
    state.totalProducts = 0;
  },
  addProducts(state, data) {
    data.forEach((element) => {
      const retval = state.products.find((o) => {
        if (o.sku == element.sku) {
          return true;
        }
      });
      if (retval == null) {
        state.products.push(element);
      } else {
        state.ShowProducts.push(element);
      }
    });
  },
  addPriceFilter(state, data) {
    state.priceFilter = data;
  },
  setPriceFilter(state, data) {
    state.priceFilter = data;
    store.commit("search/resetCurrentPage");
    store.dispatch("search/loadSearchFilters");
  },
  setPartFinderPriceFilter(state, data) {
    state.priceFilter = data;
    store.commit("search/resetCurrentPage");
    store.dispatch("search/loadFilteredSortedPartfinder");
  },
  resetPriceFilter(state) {
    state.priceFilter = [0, 0];
  },
  setPriceFilterMin(state, data) {
    state.priceFilterMin = data;
  },
  setPriceFilterMax(state, data) {
    state.priceFilterMax = data;
  },
  removePriceFilter(state) {
    const priceMin = state.priceFilterMin;
    const priceMax = state.priceFilterMax;
    state.priceFilter = [priceMin, priceMax];
  },
  addSorting(state, data) {
    if (config.sortingOptions[data]) {
      state.sorting = data;

      const [sortBy, direction = "ASC"] = config.sortingOptions[data];
      let currentSorting = null;
      if (sortBy && direction) {
        currentSorting = { sortBy, direction };
      }
      store.commit("search/addCurrentSorting", currentSorting);
    }
  },
  setSorting(state, data) {
    if (config.sortingOptions[data]) {
      state.sorting = data;
      const [sortBy, direction = "ASC"] = config.sortingOptions[data];
      let currentSorting = null;
      if (sortBy && direction) {
        currentSorting = { sortBy, direction };
      }
      store.commit("search/setCurrentSorting", currentSorting);
    }
  },
  addCurrentSorting(state, data) {
    state.currentSorting = data;
  },
  setCurrentSorting(state, data) {
    state.currentSorting = data;
    store.commit("search/resetCurrentPage");
    store.dispatch("search/loadProductsBySearch");
  },
  resetSorting(state) {
    state.sorting = "default";
    state.currentSorting = null;
  },
  setActiveFilter(state, data) {
    const obj = state.activeFilter.find((o) => {
      if (o.value == data.value) {
        return true; // stop searching
      }
    });
    if (obj == null) {
      state.activeFilter.push(data);
    }
    store.commit("search/resetCurrentPage");
    store.dispatch("search/loadSearchFilters");
  },

  //Set filters for partfinder search
  setPartfinderActiveFilter(state, data) {
    const obj = state.activeFilter.find((o) => {
      if (o.value == data.value) {
        return true; // stop searching
      }
    });
    if (obj == null) {
      state.activeFilter.push(data);
    }
    store.commit("search/resetCurrentPage");
    store.dispatch("search/loadFilteredSortedPartfinder");
  },
  
  //Remove filters for partfinder search
  removePartfinderActiveFilter(state, data) {
    if (state.activeFilter.length > 0) {
      state.activeFilter.find((o, i) => {
        if (o.value == data.value) {
          state.activeFilter.splice(i, 1);
          return true;
        }
      });
      store.commit("search/resetCurrentPage");
      store.dispatch("search/loadFilteredSortedPartfinder");
    }
  },

  removeActiveFilter(state, data) {
    if (state.activeFilter.length > 0) {
      state.activeFilter.find((o, i) => {
        if (o.value == data.value) {
          state.activeFilter.splice(i, 1);
          return true;
        }
      });
      store.commit("search/resetCurrentPage");
      store.dispatch("search/loadSearchFilters");
    }
  },
  removeAllActiveFilter(state) {
    state.activeFilter = [];
    store.commit("search/resetCurrentPage");
    store.commit("search/removePriceFilter");
    store.dispatch("search/loadSearchFilters");
  },

  removeAllActiveFilterForPartfinder  (state) {
    state.activeFilter = [];
    store.commit("search/resetCurrentPage");
    store.commit("search/removePriceFilter");
    store.commit("search/resetPriceFilter");
    store.dispatch("search/loadFilteredSortedPartfinder");
  },
  setIsBusy(state, data) {
    state.isBusy = data;
  },
};

const getters = {
  getCurrentCategory: (state) => state.current,
  getCurrentSearch: (state) => state.currentSearch,
  getProductFilters: (state) => state.productFilters,
  getActiveFilter: (state) => state.activeFilter,
  getShowProducts: (state) => state.ShowProducts,
  getCurrentPage: (state) => state.currentPage,
  getProductsPerPage: (state) => state.prodsPerPage || 12,
  getTotalPages: (state) => state.totalPages,
  getTotalProducts: (state) => state.totalProducts,
  getPriceFilter: (state) => state.priceFilter,
  getPriceFilterMin: (state) => state.priceFilterMin,
  getPriceFilterMax: (state) => state.priceFilterMax,
  getIsPriceFilterActive: (state) =>
    !(state.priceFilter[0] === 0 && state.priceFilter[1] === 0) &&
    !(
      state.priceFilter[0] === state.priceFilterMin &&
      state.priceFilter[1] === state.priceFilterMax
    ),
  getSorting: (state) => state.sorting,
  getCurrentSorting: (state) => state.currentSorting,
  getLoadProducts: (state) => state.loadProducts,
  getIsBusy: (state) => state.isBusy,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
