const Home = () =>
  import(/* webpackChunkName: "esf-home" */ '../core/views/home/HomeView.vue');
const PageNotFound = () =>
  import(
    /* webpackChunkName: "esf-page-not-found" */ '../core/views/page-not-found/PageNotFound.vue'
  );
const Login = () =>
  import(
    /* webpackChunkName: "esf-page-login" */ '../core/views/auth/LoginView.vue'
  );
const CreateAccount = () =>
  import(
    /* webpackChunkName: "esf-create-account" */ '../core/views/auth/CreateAccountView.vue'
  );
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "esf-forgot-password" */ '../core/views/auth/ForgotPasswordView.vue'
  );
const Category = () =>
  import(
    /* webpackChunkName: "sf-category-page" */ '../core/views/category/CategoryView.vue'
  );
const Faq = () =>
  import(/* webpackChunkName: "sf-faw-page" */ '../core/views/faq/FaqView.vue');

const Product = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/product/ProductView.vue'
  );

const ShoppingCart = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/shopping-cart/ShoppingCartView.vue'
  );

const MyAccountPageView = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/account/MyAccountPageView.vue'
  );

const ContactPage = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/contact/ContactView.vue'
  );
const OrderPage = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/order/OrderView.vue'
  );

const PaymentSuccess = () =>
  import(
    /* webpackChunkName: "sf-payment-success" */ "../core/views/payment/PaymentSuccess.vue"
  );
const PaymentCancel = () =>
  import(
    /* webpackChunkName: "sf-payment-cancel" */ "../core/views/payment/PaymentCancel.vue"
  );


// const SearchCategoryPage = () =>
//   import(
//     /* webpackChunkName: "sf-faw-page" */ '../core/views/search/SearchCategoryView.vue'
//   );

const SearchPage = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/search/SearchPage.vue'
  );

const HerolistPage = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/contentpage/HerolistPage.vue'
  );

const WishlistPage = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/wishlist/WishlistPageView.vue'
  );

const CmsPage = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ '../core/views/static-page/StaticPage.vue'
  );

const Blogs = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ "@/esf_utrecht_bankxl/core/views/blog/Blogs.vue"
  );

const Blog = () =>
  import(
    /* webpackChunkName: "sf-faw-page" */ "@/esf_utrecht_bankxl/core/views/blog/Blog.vue"
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "sf-reset-password" */ "../core/views/auth/ResetPassword.vue"
  );
const Brands = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/brands/Brands.vue");

const Brand = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/brands/Brand.vue");

const CustomerServiceOverview = () =>
  import(
    /* webpackChunkName: "sf-cs-page" */ "@/esf_utrecht_bankxl/core/views/customerservice/CustomerServiceOverview.vue"
  );

const CustomerService = () =>
  import(
    /* webpackChunkName: "sf-cs-page" */ "@/esf_utrecht_bankxl/core/views/customerservice/CustomerService.vue"
  );

// RMA: Begin

const RmaGuestRequest = () =>
  import(
    /* webpackChunkName: "esf-rma-page" */ "@/esf_utrecht_bankxl/core/views/rma/guest/Request.vue"
  );

const RmaGuestNewReturn = () =>
  import(
    /* webpackChunkName: "esf-rma-page" */ "@/esf_utrecht_bankxl/core/views/rma/guest/NewReturn.vue"
  );

const RmaPaymentRequest = () =>
  import(
    /* webpackChunkName: "esf-rma-page" */ "@/esf_utrecht_bankxl/core/views/rma/PaymentRequest.vue"
  );

const RmaUserRequest = () =>
  import(
    /* webpackChunkName: "esf-rma-page" */ "@/esf_utrecht_bankxl/core/views/rma/user/Request.vue"
  );
//const RmaReturnView = () =>
//  import(
//    /* webpackChunkName: "esf-rma-page" */ "@/esf_utrecht_bankxl/core/views/rma/Detail.vue"
//  );  


// RMA: End

// Landing Page: Begin

const LandingPage = () =>
  import(
    /* webpackChunkName: "esf-landing-category-page" */ "@/esf_utrecht_bankxl/core/views/landing-page/LandingPage.vue"
  );

// Landing Page: Ends

const routes = [
  { name: 'home', path: '/', component: Home, pathToRegexpOptions: { strict: true } },
  { name: 'page-not-found', path: '/page-not-found/', component: PageNotFound, pathToRegexpOptions: { strict: true } },
  { name: 'login', path: '/login/', component: Login },
  { name: 'create-account', path: '/create-account/', component: CreateAccount, pathToRegexpOptions: { strict: true } },
  { name: "brands", path: "/merken/", component: Brands },
  { name: "brand-detail", path: "/merken/:code", component: Brand },
  {
    name: 'forgot-password',
    path: '/forgot-password/',
    component: ForgotPassword, pathToRegexpOptions: { strict: true }
  },
  {
    name: "reset-password",
    path: "/customer/account/createPassword/",
    component: ResetPassword, pathToRegexpOptions: { strict: true }
  },
  {
    name: 'account',
    path: '/account/',
    component: MyAccountPageView, pathToRegexpOptions: { strict: true }
  },
  { name: 'shopping-cart', path: '/shopping-cart/', component: ShoppingCart, pathToRegexpOptions: { strict: true } },
  { name: 'category-page', path: '/category-page/', component: Category, pathToRegexpOptions: { strict: true } },
  { name: 'faq-page', path: '/faq/', component: Faq, pathToRegexpOptions: { strict: true } },
  { name: 'product-SimpleProduct', path: '/product/', component: Product, pathToRegexpOptions: { strict: true } },
  {
    name: 'product-ConfigurableProduct',
    path: '/productconfig/',
    component: Product, pathToRegexpOptions: { strict: true }
  },
  {
    name: 'product-BundleProduct',
    path: '/productbundle/',
    component: Product, pathToRegexpOptions: { strict: true }
  },
  {
    name: 'product-page',
    path: '/product/',
    component: Product, pathToRegexpOptions: { strict: true }
  },

  {
    name: 'product-LookProduct',
    path: '/productlook/',
    component: Product, pathToRegexpOptions: { strict: true }
  },
  {
    name: 'contact',
    path: '/contact/',
    component: ContactPage, pathToRegexpOptions: { strict: true }
  },
  {
    name: 'order',
    path: '/order/',
    component: OrderPage, pathToRegexpOptions: { strict: true }
  },
  {
    name: "onpagesuccess",
    path: "/checkout/onepage/success/",
    component: PaymentSuccess, pathToRegexpOptions: { strict: true }
  },
  {
    name: "onpagecancel",
    path: "/checkout/onepage/cancel/",
    component: PaymentCancel, pathToRegexpOptions: { strict: true }
  },
  {
    name: "multisafepaysuccess",
    path: "/multisafepay/connect/success",
    component: PaymentSuccess, pathToRegexpOptions: { strict: true }
  },
  {
    name: "multisafepaycancel",
    path: "/multisafepay/connect/cancel",
    component: PaymentCancel, pathToRegexpOptions: { strict: true }
  },

  // {
  //   name: 'SearchCategory',
  //   path: '/search-category/',
  //   component: SearchCategoryPage,pathToRegexpOptions: { strict: true }
  // },
  {
    name: 'Search',
    path: '/search/',
    component: SearchPage, pathToRegexpOptions: { strict: true }
  },
  {
    name: 'HerolistPage',
    path: '/contentpage/',
    component: HerolistPage, pathToRegexpOptions: { strict: true }
  },
  {
    name: 'Wishlist',
    path: '/wishlist/',
    component: WishlistPage, pathToRegexpOptions: { strict: true }
  },
  { name: 'cms-page', path: '/cms-page/', component: CmsPage, pathToRegexpOptions: { strict: true } },

  { name: "blogs", path: "/blog/", component: Blogs, pathToRegexpOptions: { strict: true } },
  { name: "blog", path: "/blog/:code/", component: Blog, pathToRegexpOptions: { strict: true } },
  {
    name: "blog-category",
    path: "/blog/categorie/:category_id/",
    component: Blogs, pathToRegexpOptions: { strict: true }
  },
  { name: "klantenserviceberichten", path: "/klantenservice/", component: CustomerServiceOverview, pathToRegexpOptions: { strict: true } },
  { name: "klantenservice", path: "/klantenservice/:code/", component: CustomerService, pathToRegexpOptions: { strict: true } },
  {
    name: "klantenservice-category",
    path: "/klantenservice/categorie/:category_id/",
    component: CustomerServiceOverview, pathToRegexpOptions: { strict: true }
  },
  { path: '/customer/account', redirect: '/login/' },
  { path: '/page-not-found', redirect: '/page-not-found/' },

  // RMA: Begin
  { path: '/retourneren/', redirect: '/returns/guest/' },
  { name: "rma_guest_return_validate", path: "/returns/guest/", component: RmaGuestRequest, pathToRegexpOptions: { strict: true } },
  { name: "rma_guest_return_new", path: "/returns/guest/confirm/", component: RmaGuestNewReturn, pathToRegexpOptions: { strict: true } },
  { name: "rma_payment_request", path: "/returns/order/payment/", component: RmaPaymentRequest, pathToRegexpOptions: { strict: true } },
  { name: "rma_user_return_validate", path: "/account/returns/new/:orderNumber/", component: RmaUserRequest, pathToRegexpOptions: { strict: true } },
  // RMA: End

  // Landing Page: Begin
  { name: "LandingPageCategory", path: '/testPage', component: LandingPage, pathToRegexpOptions: { strict: true } },
  // Landing Page: Ends
];

export default routes;
