import { getProducts, getMeta } from "@storefront/core/data-resolver/landingPage";

const state = () => ({
    landingPageData: null,
    totalPages: 1,
    totalProducts: 0,
    productFilter: null,
});

const actions = {

    /**
     * get Landing Page Resolve / Meta
     *
     */
    async getMeta({ commit }, { meta }) {

        const urlKey = meta.relative_url;
        const retval = await getMeta(urlKey);
        
        const productFilter = retval.data.data.landingPage.product_filters;
        const landingPageData = retval.data.data.landingPage;
            
        commit("setProductFilter", productFilter);
        commit("setLandingPageData", landingPageData);

        if(retval){
            return retval;
        }
    },

    /**
     * load Landing Page products data
     *
     */
    async getProducts({ commit, getters }) {
        const productFilterData = getters["getProductFilters"];

        const retval = await getProducts(productFilterData);
        const totalPages = retval.data.data.products.page_info.total_pages;
        const totalProducts = retval.data.data.products.total_count;

        commit("setTotalPages", totalPages);
        commit("setTotalProducts", totalProducts);

        if(retval){
            return retval;
        }
    },
};

const mutations = {
    // always and only change vuex state through mutations.

    /**
     * set productFilter
     *
     * @param {object} state
     * @param {object} data
     * @private
     */
    setProductFilter(state, payload) {
        state.productFilter = payload;
    },

    /**
     * set landingPageData
     *
     * @param {object} state
     * @param {object} data
     * @private
     */
    setLandingPageData(state, payload) {
        state.landingPageData = payload;
    },

    /**
     * set totalPages
     *
     * @param {object} state
     * @param {integer} data
     * @private
     */
    setTotalPages(state, data) {
        state.totalPages = data;
    },
  
    /**
     * set totalProducts
     *
     * @param {object} state
     * @param {integer} data
     * @private
     */
    setTotalProducts(state, data) {
        state.totalProducts = data;
    },

};

const getters = {
    getLandingPageData: (state) => state.landingPageData,
    getTotalPages: (state) => state.totalPages,
    getTotalProducts: (state) => state.totalProducts,
    getProductFilters: (state) => state.productFilter,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};