import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";


export async function getMeta(urlKey) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + config.shop.accessToken,
        Store: storeview,
    };
    
    const query =
        "{landingPage(identifier:"+urlKey+"){content content_heading meta_description meta_keywords meta_title  product_filters relative_url secondary_content title}}";
    
    const retval = await axios({
        url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
        method: "GET",
        headers: headers,
    }).catch((e) => {
        Logger.error("loadContentPageByIdentifier", "data-resolver cms", e)();
        throw e;
    });
    if (retval) {
        return retval;
    } else {
        return false;
    }
}
/**
 * Load content page from maggento
 * 
 * @param {string} id 
 `* @returns return content page object or false
 */
export async function getProducts(productFilterData) {

    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + config.shop.accessToken,
        Store: storeview,
    };

    //remove the "" from the query string
    let cleanedData = productFilterData.replace(/"/g, '');

    const query = 
        "{ products(pageSize: 15 currentPage: 1 filter: "+cleanedData+"  ) { total_count page_info { page_size current_page total_pages } aggregations { min_value max_value attribute_code count label options { count label value swatch_data { type value} } }  items { sku unit is_flooring_product stock_qty stock_display_limit titel60 DeliveryTime { color icon long short } labels { id category { type position size text css image_url } product { type position size text css image_url } } cashback_promotion { amount {currency value} description start_date end_date } stock_qty sales_data { last_order_date orders qty_ordered } filter_attributes{ attribute_code attribute_id label values { option_id option_label } }  ... on BundleProduct { items { option_id title position } } thumbnail { thumbnail small medium large x_large } url_key name __typename canonical_url rating_summary review_count meta_title meta_keyword meta_description new_from_date new_to_date created_at stock_status information_attributes { attribute_code attribute_id label value } manufacturer_price { price { currency value } discount { amount_off percent_off } } price_range { minimum_price { discount { amount_off percent_off } regular_price { value } final_price { value } } maximum_price { discount { amount_off percent_off } regular_price { value } final_price { value } } } special_from_date special_to_date special_price ... on ConfigurableProduct { configurable_options { label position use_default attribute_code values { value_index label } } } } } }";
    const retval = await axios({
        url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
        method: "GET",
        headers: headers,
    }).catch((e) => {
        Logger.error("loadContentPageByIdentifier", "data-resolver cms", e)();
        throw e;
    });
    if (retval) {
        return retval;
    } else {
        return false;
    }
}
