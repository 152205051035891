export default {
  namespaced: true,

  state() {
    return {
      isCreateAccount: false,
      formData: null,
    };
  },

  getters: {
    getCreateAccount: (state) => state.isCreateAccount,
    getCreateAccountData: (state) => state.formData,
  },

  mutations: {
    changeCreateAccount(state, payload) {
      state.isCreateAccount = payload;
    },
    setAccountFormData(state, payload) {
      state.formData = payload;
    },
  },

  actions: {
    changeCreateAccount({ commit }, payload) {
      commit("changeCreateAccount", payload);
    },
    addAccountFormData({ commit }, payload) {
      commit("setAccountFormData", payload);
    },
  },
};
